declare let global: any;
import * as buffer from 'buffer';
global.Buffer = global.Buffer || buffer.Buffer;
import crypto from 'crypto-browserify';
import { environment } from 'src/environments/environment';

const defaultCipher = 'aes-256-cbc';


export const encrypt = (input) => {
    const key = buffer.Buffer.from(environment.encrpt.key.split('').reverse().join(''), 'hex');
    const iv = buffer.Buffer.from(environment.encrpt.iv.split('').reverse().join(''), 'hex');
    const cipher = crypto.createCipheriv(defaultCipher, key, iv);
    return cipher.update(input,'utf8', 'hex') + cipher.final('hex');
};

export const decrypt = (encryptedInput) => {
    const key = environment.encrpt.key.split('').reverse().join('');
    const iv = environment.encrpt.iv.split('').reverse().join('');
    const decipher = crypto.createDecipheriv(defaultCipher, key, iv);
    let decrypted = decipher.update(encryptedInput,'hex','utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
};
