// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const envConfig = {
  dev: {
    encrpt:{
      key: '791ab4d4e9a5440a03b57faf51d98563ed4e64984873bc1f60e71bf1e925189c',
      iv: '71a666234afe0cda0a7475b59794ab1d',
    },
    url: 'https://v19pj7t13e.execute-api.us-east-2.amazonaws.com/empresas360/api'
  },
  pre: {
    encrpt: {
    key: '8110a1b3da916410e6b882d705568b6aeb6ffe79099379bb0440f7eb42aa0517',
    iv: '744962b6cb46f430c3676c481da700ae',
    },
    url: 'https://s4xbpu66nh.execute-api.eu-west-1.amazonaws.com/empresas360esp/api'
  },
  pro: {
    encrpt: {
    key: 'e1c9f38465817f7cebf990f7336a0d64fdad1453e1c46fcf9698e712591e7c9e',
    iv: '967f62719ddfac8e0dd40ed3d1eed22b',
    },
    url: 'https://mimkffpdmg.execute-api.eu-west-1.amazonaws.com/empresas360esp/api'
  }
};

const envLabel = 'pro';

export const environment = {
  production: false,
  mocksEnabled: false,
  ...envConfig[envLabel]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
