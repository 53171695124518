import { Injectable, Injector } from '@angular/core';
import { Events, NavParams, LoadingController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie';
import { UtilsService } from '../utils/utils.service';
import { Observable, of, from, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DateService } from '../date/date.service';
import { IdentificationService } from '../identification/identification.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { User } from 'src/app/models/user';
import { MapfreResponseEntity } from 'src/app/models/mapfre-response-entity';
import * as _ from 'lodash';
import { MapfreResponse } from 'src/app/models/mapfre-response';
import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ServerResponse } from 'src/app/models/server-response';
import { Storage } from '@ionic/storage';
import { connection } from './api.connections';
import { UserService } from '../user/user.service';
import { Task } from 'src/app/models/task';
import { TranslationService } from '../translation/translation.service';
import { Form, RisksForm, UserAnswers, ExpirationsAnswers } from 'src/app/models/form';
import { ActivatedRoute } from '@angular/router';
import { encrypt } from '../utils/utils-helper';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  static componentName = 'api-service';
  static errorEvent = `${ApiService.componentName}:error`;
  protected http: HttpClient;
  protected events: Events;
  protected storage: Storage;

  protected dateService: DateService;
  protected identificationService: IdentificationService;

  private cookieService: CookieService;
  private loggedIn: any;
  private lastVisitedPage: {
    name: string ;
    params: NavParams;
  };

  constructor(
    protected injector: Injector,
    private userService: UserService
  ) {
    this.http = injector.get(HttpClient);
    this.events = injector.get(Events);
    this.storage = injector.get(Storage);
    this.cookieService = injector.get(CookieService);
    this.dateService = injector.get(DateService);
    this.identificationService = injector.get(IdentificationService);
  }

  getQueryUrl(isSFServer: boolean, suffix: string, parameters?: any, apiVersion?: number): string {
    let queryUrl = isSFServer ? `${this.userService.getSfHost()}${connection.apiSfFixedPath}` : `${connection.apiUrl}`;
    queryUrl += isSFServer ? '' : `/${this.getApiVersion(apiVersion)}`;
    queryUrl += `${encodeURI(suffix)}`;
    if (parameters && Object.keys(parameters).length) {
      queryUrl += `?`;
      Object.keys(parameters).forEach(key => {
        if (Array.isArray(parameters[key])) {
          for (const parameter of parameters[key]) {
            queryUrl += `${key}=${parameter}&`;
          }
        } else {
          queryUrl += `${key}=${parameters[key]}&`;
        }
      });
    }

    return queryUrl;
  }

  getApiVersion(version?: number): string {
    if (version) {
      return connection[`apiVersionV${version}`];
    } else {
      return connection.apiVersionV1;
    }
  }

  getLanguage(requestedLanguage?: string): string {
    const forcedLanguage: string = ConfigurationService.config ? this.cookieService.get(ConfigurationService.config.forcedLanguageKey) : '';
    const userData: User = this.userService.getLoggedUserData();
    const userLanguage: string = userData ? userData.preferredLanguageId : undefined;
    const browserLanguage: string = navigator.language;
    const defaultLanguage: string = ConfigurationService.config.defaultLanguage;

    return forcedLanguage || requestedLanguage || userLanguage || browserLanguage || defaultLanguage;
  }

  getObservableForSuffix(
    isSFServer: boolean,
    suffix: string,
    authenticated: boolean,
    parameters?: any,
    apiVersion?: number
  ) {
    return from(this.getHeaders(authenticated))
      .pipe<any>(mergeMap(headers => this.http.get(this.getQueryUrl(isSFServer, suffix, parameters, apiVersion), { headers })));
  }

  getForSuffix<T>(
    isSFServer: boolean,
    suffix: string,
    authenticated: boolean,
    parameters?: any,
    apiVersion?: number
    ): Promise<T>{
    return new Promise((resolve, reject) => {
      this.getObservableForSuffix(isSFServer, suffix, authenticated, parameters, apiVersion).subscribe((result) => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
    }

  postObservableForSuffix(
    isSFServer: boolean,
    suffix: string,
    authenticated: boolean,
    parameters?: any,
    data?: any,
    apiVersion?: number
  ) {
    return from(this.getHeaders(authenticated))
      .pipe<any>(mergeMap(headers => this.http.post<ServerResponse>(
        this.getQueryUrl(isSFServer, suffix, parameters, apiVersion), data, { headers })));
  }

  putObservableForSuffix(
    isSFServer: boolean,
    suffix: string,
    authenticated: boolean,
    parameters?: any,
    data?: any,
    apiVersion?: number
  ) {
    return from(this.getHeaders(authenticated))
      .pipe<any>(mergeMap(headers => this.http.put<ServerResponse>(
        this.getQueryUrl(isSFServer, suffix, parameters, apiVersion), data, { headers })));
  }

  deleteObservableForSuffix(
    isSFServer: boolean,
    suffix: string,
    authenticated: boolean,
    parameters?: any,
    data?: any,
    apiVersion?: number
  ) {
    return from(this.getHeaders(authenticated))
      .pipe<any>(mergeMap(headers => this.http.delete<ServerResponse>(
        this.getQueryUrl(isSFServer, suffix, parameters, apiVersion), { headers })));
  }

  getHeaders(authenticated: boolean, language?: string): Promise<HttpHeaders> {
    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: ''
      };
      if (language) {
        headers['Accept-Language'] = this.getLanguage(language);
      } else {
        headers['Accept-Language'] = this.getLanguage(null);
      }
      if (authenticated) {
        this.identificationService.getApiKey().then(apiKey => {
          headers.Authorization = apiKey;
          resolve(new HttpHeaders(headers));
        });
      } else {
        resolve(new HttpHeaders(headers));
      }
    });
  }

  isResponseValid(response: ServerResponse): boolean {
    return this.isMapfreResponseValid(response);
  }

  isMapfreResponseValid(mapfreResponse: MapfreResponse): boolean {
    return mapfreResponse ? this.isMapfreResponseEntityValid(mapfreResponse.response) : false;
  }

  areResponsesValid(responses: ServerResponse[]): boolean {
    responses.forEach(response => {
      if (!this.isResponseValid(response)) {
        return false;
      }
    });
    return true;
  }

  areMapfreResponsesValid(mapfreResponses: MapfreResponse[]): boolean {
    mapfreResponses.forEach(mapfreResponse => {
      if (!this.isMapfreResponseValid(mapfreResponse)) {
        return false;
      }
    });
    return true;
  }

  isResponseEntityValid(response: ServerResponse): boolean {
    return this.isMapfreResponseEntityValid(response.response);
  }

  isMapfreResponseEntityValid(mapfreResponseEntity: MapfreResponseEntity): boolean {
    return mapfreResponseEntity && mapfreResponseEntity.code === 0;
  }

  setLastVisitedPageWhenError(route: ActivatedRoute): void {
    const name: string = UtilsService.getLastViewName(route);
    const params: any = UtilsService.getLastViewParameters(route);
    this.lastVisitedPage = {name, params};
  }

  getLastVisitedPageBeforeError(): any {
    return !_.isEmpty(this.lastVisitedPage) ? this.lastVisitedPage : null ;
  }

  bindToInputs(target: any, values: any): void {
    for (const prop in values) {
      if (values.hasOwnProperty(prop)) {
        target[prop] = values[prop];
      }
    }
  }



  setLoggedIn(): void {
    this.loggedIn = true;
  }

  logout(): void {
    this.loggedIn = false;
  }

  sendLoginRequest(request: any): any {
    if (request.rememberMe) {
      this.userService.setRememberedUser(request.user_pass_login.user);
      this.userService.setUserName(request.user_pass_login.user);
    } else {
        this.userService.clearRememberedUser();
    }
    return this.postObservableForSuffix(false, '/authentications/login', false, null, request);
  }

  areResponseValid(responses: any[]): any {
    let valid = true;
    if (responses && responses.length) {
      responses.forEach((response) => {
        valid = valid && this.isResponseValid(response);
      });
    }

    return valid;
  }

  addAuthorization(headers: Headers): Promise<Headers> {
    return new Promise((resolve, reject) => {
      this.identificationService.getApiKey().then(apiKey => {
        if (apiKey && apiKey !== undefined) {
          headers.append('Authorization', apiKey);
        }
        resolve(headers);
      });
    });
  }

  getForm(companyId: any): Observable<any> {
    return this.getObservableForSuffix(true, `/agent/${encrypt(this.userService.getClientId())}/form/${companyId}`, true, null, null);
  }

  postForm(companyId: any, userAnswers: UserAnswers, expirationsAnswers: ExpirationsAnswers, submit: boolean): Observable<any> {
    const body = {
      companyId,
      userAnswers: userAnswers ? userAnswers : null,
      expirationsAnswers: expirationsAnswers ? expirationsAnswers : null,
      isSubmit: submit
    };
    return this.postObservableForSuffix(true, `/agent/${encrypt(this.userService.getClientId())}/form/${companyId}`, true, null, body);
  }

  getPDF(companyId: any, type: string): any {
    const url = `/agent/${encrypt(this.userService.getClientId())}/form/${companyId}/${type}`;
    return this.getObservableForSuffix(true,url, true, null, null);
  }

  postMails(companyId: any, type: string, mails: string[]): Observable<any> {
    const body: any = {};
    body.mails = mails;
    const url = `/agent/${encrypt(this.userService.getClientId())}/form/${companyId}/${type}/mail`;
    return this.postObservableForSuffix(true, url, true, null, body);
  }

  getPendingTasks(): any {
    return this.getObservableForSuffix(true, `/agent/${encrypt(this.userService.getClientId())}/tasks`, true, null, null);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    if (error.status === 500) {
      this.events.publish(ApiService.errorEvent, error);
    }
    throwError(error.statusText);
  }
}
