import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuardService] },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuardService] },
  { path: 'company/:id/form', loadChildren: () => import('./pages/risks-form/risks-form.module').then(m => m.RisksFormPageModule),
    canActivate: [AuthGuardService] },
  { path: 'tasks', loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksPageModule),
    canActivate: [AuthGuardService] },
  { path: 'company/:id', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyPageModule),
    canActivate: [AuthGuardService] },
  { path: 'company/:id/form/resume', loadChildren: () => import('./pages/resume/resume.module').then(m =>m.ResumePageModule),
    canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
