import { Component, OnInit, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'close-session-modal',
  templateUrl: './close-session-modal.component.html',
  styleUrls: ['./close-session-modal.component.scss'],
})
export class CloseSessionModalComponent {
  public labels: any;
  public translate: any;

  constructor(
    private userService: UserService,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private translationService: TranslationService,
    private elementRef: ElementRef,
    private modalService: ModalService
  ) {
    this.translationService.bind(this);
    this.labels = this.navParams.get('labels');
    this.modalService.setupCentering(this.elementRef);
   }

  dismiss(): void {
    this.modalCtrl.dismiss(false);
  }

  logout(): void {
    this.userService.getLogout();
    this.modalCtrl.dismiss(true);
  }

}
