import { HostListener, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { subscribe, isSupported } from 'on-screen-keyboard-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  MIN_KEYBOARD_HEIGHT = 400;

  THIN_MOBILE_MAX = 450;
  MOBILE_MAX = 767;
  TABLET_MIN = 768;
  SMALL_TABLET_MAX = 991
  WIDE_TABLET_MIN = 992;
  TABLET_MAX = 1199;
  DESKTOP_MIN = 1200;

  width
  height

  isKeyboardVisible = false;

  constructor(private platform: Platform) {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    })


    subscribe(visibility => {
      this.isKeyboardVisible = visibility === 'visible';
    });


  }



  getDeviceSize() {
    return {
      width: this.width, height: this.height
    }
  }


}
