import { Injectable } from '@angular/core';
import { TranslationService } from '../translation/translation.service';
import { ApiService } from '../api/api.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { defaultComponentsConfiguration } from 'src/app/models/default-component-configuration';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {

  constructor(
    private translationService: TranslationService,
    private apiService: ApiService
  ) { }

  initializeAppLanguage(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.getObservableForSuffix(false, '/labels', false).subscribe(
        response => {
          this.translationService.setTranslations(response.data);
          this.translationService.bind(this);
          resolve(null);
        }
      );
    });
  }

  initializeAppComponentConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      ConfigurationService.configure(defaultComponentsConfiguration);
      resolve(null);
    });
  }
}
